
import { ApolloClient, HttpLink, ApolloLink, InMemoryCache, from } from '@apollo/client';
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';

const cache = new InMemoryCache();
const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_SERVER_URI });
const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers={} }) => ({
    headers: {
      ...headers,
      // TODO: Add authorization header
    }
  }));
  return forward(operation);
});

const graphQlClient = new ApolloClient({
  cache,
  link: from([removeTypenameFromVariables(), authMiddleware, httpLink])
});

export default graphQlClient;
