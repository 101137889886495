
import React from 'react';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import CTAContainer from '../../@lib/components/@common/CTAContainer';
import AppBar from '../../@lib/components/@common/AppBar';
import PageTitle from '../../@lib/components/@common/PageTitle';
import SearchBar from '../../@lib/components/@common/SearchBar';
import PersonCard from '../../@lib/components/PersonCard';

import { useAuthSession } from '../../@lib/components/AuthSessionProvider';

const People:React.FC = () => {
  const {user} = useAuthSession();
  const isLoggedIn = !!user;

  return (
    <div>
      <AppBar>
        <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" flexGrow={1}>
          <PageTitle
            title="People"
            subtitle="NGO Detail will go here..."
          />
          <SearchBar
            placeholder="Search tree and keyword"
          />
        </Stack>
      </AppBar>

      <Container sx={{ my: 3 }}>
        <Paper component={Container} sx={{ py: 1, mb: 2, backgroundColor: 'primary.main', color: '#ffffff' }}>
          <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle1">People</Typography>
            <Link component={RouterLink} to="all" underline="none" color="inherit">View all</Link>
          </Stack>
        </Paper>

        <Grid container spacing={3}>
        {Array(8).fill(null).map((_, i) => (
          <Grid key={`${i}`} item xs={12} sm={6} md={4} lg={3}>
            <PersonCard />
          </Grid>
        ))}
        </Grid>
      </Container>

      <Container sx={{ my: 3 }}>
        <Paper sx={{ py: 2, display: 'flex', justifyContent: 'center' }}>
          <Link color="textSecondary" underline="none">Show more</Link>
        </Paper>
      </Container>

      {isLoggedIn && (
        <Container sx={{ my: 3 }}>
          <CTAContainer sx={{ textAlign: 'center' }}>
            <Typography variant="subtitle1" mb={2}>Add new people to the platform</Typography>
            <Button
              component={RouterLink}
              to="new"
              sx={{
                px: 6,
                backgroundColor: '#ffffff',
                color: 'primary.dark',
                '&:hover': {
                  backgroundColor: 'primary.light'
                }
              }}
            >Add People</Button>
          </CTAContainer>
        </Container>
      )}

      <Container sx={{ my: 3 }}>
        <Paper component={Container} sx={{ py: 2.5, mb: 2 }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Typography variant="h4">Latest Actions</Typography>
            <Link href="#" underline="none" color="textSecondary">View all</Link>
          </Stack>
        </Paper>

        <Paper component={Container} sx={{ py: 3 }}>
          <Stepper orientation="vertical">
          {Array(8).fill(null).map((_, i) => (
            <Step key={`${i}`}>
              <StepLabel>Person {i} acted in Sector 1</StepLabel>
            </Step>
          ))}
          </Stepper>
        </Paper>
      </Container>

      <Container sx={{ my: 3 }}>
        <Paper component={Container} sx={{ py: 2.5, mb: 2 }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Typography variant="h4">Latest Positions</Typography>
            <Link href="#" underline="none" color="textSecondary">View all</Link>
          </Stack>
        </Paper>

        <Paper component={Container} sx={{ py: 3 }}>
          <Stepper orientation="vertical">
          {Array(8).fill(null).map((_, i) => (
            <Step key={`${i}`}>
              <StepLabel>Person {i} took a position in Sector 1</StepLabel>
            </Step>
          ))}
          </Stepper>
        </Paper>
      </Container>
    </div>
  );
};

export default People;
