
import React from 'react';
import { Practice, PracticeInputData } from '../../@lib/types';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import AppBar from '../../@lib/components/@common/AppBar';
import PageTitle from '../../@lib/components/@common/PageTitle';
import SearchBar from '../../@lib/components/@common/SearchBar';
import PracticeEditor from '../../@lib/components/PracticeEditor';

import { useMemo, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery, useMutation, gql } from '@apollo/client';
import { useNotify } from '../../@lib/components/@common/NotificationsProvider';

const fetchPracticeQueryDocument = gql`
query FetchPractice ($practiceId: ID!) {
  practice (id: $practiceId) {
    id
    title
    description
    date
    link
    country {
      id
    }
    theme {
      id
    }
    practiceCategory {
      id
    }
    practiceType {
      id
    }
    policy {
      id
    }
  }
}
`;

const updatePracticeMutationDocument = gql`
mutation UpdatePractice ($practiceUpdateInput: PracticeUpdateInput!) {
  practiceUpdate (input: $practiceUpdateInput) {
    practice {
      id
    }
  }
}
`;

const PracticeEdit:React.FC = () => {
  const notify = useNotify();
  const navigate = useNavigate();
  const params = useParams();
  const {data, loading} = useQuery<{practice: Practice}>(fetchPracticeQueryDocument, { variables: { practiceId: params.practiceId } });
  const [updatePractice, { loading:updating }] = useMutation(updatePracticeMutationDocument);

  const initialValues:PracticeInputData|undefined = useMemo(() => {
    if (!data?.practice)
      return undefined;

    // Destructure first to get rid of values not accepted as of type `PracticeInput`.
    const {
      id,
      country,
      theme,
      practiceCategory,
      practiceType,
      policy,
      ...otherFields
    } = data.practice;

    // Re-structure and return `PracticeInput` type object.
    return {
      ...otherFields,
      countryId: country.id,
      themeId: theme.id,
      practiceCategoryId: practiceCategory.id,
      practiceTypeId: practiceType.id,
      policyId: policy.id
    };
  }, [data]);

  /**
   * handleFinish()
   */
  const handleFinish = useCallback(async (data:PracticeInputData) => {
    try {
      await updatePractice({
        variables: {
          practiceUpdateInput: {
            id: params.practiceId,
            practiceInput: data
          }
        }
      });

      navigate(`/practices/${params.practiceId}`, { replace: true });
    }
    catch (err) {
      notify.error((err as Error));
    }
  }, [updatePractice, navigate, params, notify]);

  /**
   * handleCancel()
   */
  const handleCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <Box mb={24}>
      <AppBar>
        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2} flexGrow={1}>
          <PageTitle
            title="Edit practice"
          />

          <SearchBar
            placeholder="Search tree and keyword"
          />
        </Stack>
        {loading && <LinearProgress sx={{ position: 'absolute', left: 0, right: 0, bottom: 0 }} />}
      </AppBar>

      <Container sx={{ my: 3 }}>
        <Paper component={Container} sx={{ py: 3 }}>
          <PracticeEditor
            initialValues={initialValues}
            disabled={loading || updating}
            onFinish={handleFinish}
            onCancel={handleCancel}
          />
        </Paper>
      </Container>
    </Box>
  );
};

export default PracticeEdit;
