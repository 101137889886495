
import React from 'react';
import { IDType, OptionType } from '../../types';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import SvgIcon from '@mui/material/SvgIcon';
import { Controller } from 'react-hook-form';
import { ReactComponent as UploadCloudIcon } from '../../icons/upload-cloud-icon.svg';

import { useState, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

export type PracticeInfoFormProps = {
  countryOptions: OptionType<IDType>[];
  themeOptions: OptionType<IDType>[];
  categoryOptions: (OptionType<IDType> & {
    practiceTypeOptions: OptionType<IDType>[];
  })[];
  disabled?: boolean;
};

const PracticeInfoForm:React.FC<PracticeInfoFormProps> = (props) => {
  const {
    countryOptions,
    themeOptions,
    categoryOptions,
    disabled
  } = props;

  const { trigger, setValue, getValues } = useFormContext();
  const [selectedCategoryId, setSelectedCategoryId] = useState('');

  const practiceTypeOptions = useMemo(() => {
    const selectedCategory = categoryOptions.find((op) => String(op.value) === String(selectedCategoryId));
    return selectedCategory?.practiceTypeOptions || [];
  }, [categoryOptions, selectedCategoryId]);

  return (
    <Stack direction={{ xs: 'column-reverse', md: 'row' }} gap={3}>
      <Stack flex={1} gap={2}>
        <FormControl fullWidth>
          <FormLabel>Upload Image</FormLabel>
          <Paper component={Stack} sx={{ py: 10 }} alignItems="center" variant="outlined">
            <Avatar sx={{ mb: 2 }}>
              <SvgIcon component={UploadCloudIcon} inheritViewBox />
            </Avatar>
            <Typography mb={0.5}>Click to upload or drag and drop</Typography>
            <Typography variant="caption" color="textSecondary">SVG, PNG, JPG or GIF (max. 800x400px)</Typography>
          </Paper>
        </FormControl>

        <Controller
          name="countryId"
          rules={{ required: 'Required!' }}
          render={({ field, fieldState: {error} }) => (
            <FormControl fullWidth required error={!!error} disabled={disabled}>
              <FormLabel>Country</FormLabel>
              <Select
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  trigger(field.name);
                }}
                displayEmpty
              >
                <MenuItem value="" disabled><em>Select country</em></MenuItem>
                {countryOptions.map((op, i) => (
                  <MenuItem key={`${op.value}-${i}`} value={op.value}>{op.label}</MenuItem>
                ))}
              </Select>
              <FormHelperText>{error && error.message}</FormHelperText>
            </FormControl>
          )}
        />

        <Controller
          name="themeId"
          rules={{ required: 'Required!' }}
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth required error={!!error} disabled={disabled}>
              <FormLabel>Theme</FormLabel>
              <Select
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  trigger(field.name);
                }}
                displayEmpty
              >
                <MenuItem value="" disabled><em>Select theme</em></MenuItem>
                {themeOptions.map((op, i) => (
                  <MenuItem key={`${op.value}-${i}`} value={op.value}>{op.label}</MenuItem>
                ))}
              </Select>
              <FormHelperText>{error && error.message}</FormHelperText>
            </FormControl>
          )}
        />

        <Controller
          name="practiceCategoryId"
          rules={{ required: 'Required!' }}
          render={({field, fieldState: { error }}) => (
            <FormControl fullWidth required error={!!error} disabled={disabled}>
              <FormLabel>Category</FormLabel>
              <Select
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  setSelectedCategoryId(getValues(field.name))
                  setValue('practiceTypeId', '', { shouldValidate: true });
                  trigger(field.name);
                }}
                displayEmpty
              >
                <MenuItem value="" disabled><em>Select category</em></MenuItem>
                {categoryOptions.map((op, i) => (
                  <MenuItem key={`${op.value}-${i}`} value={op.value}>{op.label}</MenuItem>
                ))}
              </Select>
              <FormHelperText>{error && error.message}</FormHelperText>
            </FormControl>
          )}
        />

        {practiceTypeOptions.length > 0 && (
          <Controller
            name="practiceTypeId"
            rules={{ required: 'Required!' }}
            render={({field, fieldState: { error }}) => (
              <FormControl required fullWidth error={!!error} disabled={disabled}>
                <FormLabel>Type</FormLabel>
                <Select
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    trigger(field.name);
                  }}
                  displayEmpty
                >
                  <MenuItem value="" disabled><em>Select type</em></MenuItem>
                  {practiceTypeOptions.map((op, i) => (
                    <MenuItem key={`${op.value}-${i}`} value={op.value}>{op.label}</MenuItem>
                  ))}
                </Select>
                <FormHelperText>{error && error.message}</FormHelperText>
              </FormControl>
            )}
          />
        )}
      </Stack>

      <Stack flex={1} gap={2}>
        <Controller
          name="title"
          rules={{ required: 'Required!' }}
          render={({field, fieldState: { error }}) => (
            <FormControl required error={!!error} disabled={disabled}>
              <FormLabel>Practice Name</FormLabel>
              <OutlinedInput
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  trigger(field.name);
                }}
              />
              <FormHelperText>{error && error.message}</FormHelperText>
            </FormControl>
          )}
        />

        <Controller
          name="description"
          render={({field}) => (
            <FormControl disabled={disabled}>
              <FormLabel>Practice Description</FormLabel>
              <OutlinedInput
                {...field}
                multiline
                rows={3}
              />
            </FormControl>
          )}
        />

        <Controller
          name="date"
          render={({field}) => (
            <FormControl disabled={disabled}>
              <FormLabel>Date created</FormLabel>
              <OutlinedInput
                {...field}
              />
            </FormControl>
          )}
        />

        <FormControl fullWidth>
          <FormLabel>Upload document</FormLabel>
          <Paper component={Stack} sx={{ py: 4 }} alignItems="center" variant="outlined">
            <Avatar sx={{ mb: 2 }}>
              <SvgIcon component={UploadCloudIcon} inheritViewBox />
            </Avatar>
            <Typography mb={0.5}>Click to upload or drag and drop</Typography>
            <Typography variant="caption" color="textSecondary">Xls, Pdf or Graph are allowed to upload</Typography>
          </Paper>
        </FormControl>

        <Controller
          name="link"
          render={({field}) => (
            <FormControl disabled={disabled}>
              <FormLabel>External practice link</FormLabel>
              <OutlinedInput
                {...field}
              />
            </FormControl>
          )}
        />
      </Stack>
    </Stack>
  );
};

export default PracticeInfoForm;
