
import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import AppBar from '../@lib/components/@common/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabLabel from '../@lib/components/@common/TabLabel';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '../@lib/components/@common/TableHead';
import TableBody from '../@lib/components/@common/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import StatusTag from '../@lib/components/@common/StatusTag';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import SearchBar from '../@lib/components/@common/SearchBar';
import PageTitle from '../@lib/components/@common/PageTitle';
// import PolicyCard from '../@lib/components/PolicyCard';
import PersonCard from '../@lib/components/PersonCard';

const Dashboard:React.FC = () => {
  return (
    <div>
      <AppBar>
        <PageTitle
          title="Dashboard"
        />
      </AppBar>

      {/* BEGIN: Submissions data */}
      <Container sx={{ my: 3 }}>
        <Paper component={Container} sx={{ py: 3, mb: 2 }}>
          <Box mb={2}>
            <Typography variant="h4">Submissions Tab</Typography>
            <Typography variant="caption">Categorized view of all the submissions you have made.</Typography>
          </Box>
          <Stack direction={{ lg: 'row' }} gap={2} justifyContent="space-between" alignItems="center">
            <Tabs value={0}>
              <Tab label={<TabLabel count={157}>All submissions</TabLabel>} />
              <Tab label={<TabLabel count={57}>Approved</TabLabel>} />
              <Tab label={<TabLabel count={50}>Pending</TabLabel>} />
              <Tab label={<TabLabel count={50}>Rejected</TabLabel>} />
            </Tabs>

            <SearchBar
              placeholder="Search your submissions"
            />
          </Stack>
        </Paper>

        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Uploaded date</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {Array(7).fill(null).map((_, i) => (
                  <TableRow key={`${i}`}>
                    <TableCell>{i+1}</TableCell>
                    <TableCell>Policy</TableCell>
                    <TableCell>Name Policy {i+1}</TableCell>
                    <TableCell>Short description goes here</TableCell>
                    <TableCell padding="checkbox"><StatusTag label="Pending" status="pending" /></TableCell>
                    <TableCell align="right">18.05.2024</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box component={Container} sx={{ py: 3 }} textAlign="right">
            <Link href="#" underline="none" color="textSecondary">View all</Link>
          </Box>
        </Paper>
      </Container>
      {/* END: Submissions data */}

      {/* BEGIN: List of policies */}
      <Container sx={{ my: 3 }}>
        <Paper component={Container} sx={{ py: 3, mb: 2 }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h4">List of policies data</Typography>
            <Link href="#" underline="none" color="textSecondary">View all</Link>
          </Stack>
        </Paper>

        <Grid container spacing={2}>
        {Array(4).fill(null).map((_, i) => (
          <Grid key={`${i}`} item xs={12} md={6} lg={3}>
            {/* <PolicyCard /> */}
          </Grid>
        ))}
        </Grid>
      </Container>
      {/* END: List of policies */}

      {/* BEGIN: List of practices */}
      <Container sx={{ my: 3 }}>
        <Paper component={Container} sx={{ py: 3, mb: 2 }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h4">List of practices data</Typography>
            <Link href="#" underline="none" color="textSecondary">View all</Link>
          </Stack>
        </Paper>

        <Grid container spacing={2}>
        {Array(4).fill(null).map((_, i) => (
          <Grid key={`${i}`} item xs={12} md={6} lg={3}>
            {/* <PolicyCard /> */}
          </Grid>
        ))}
        </Grid>
      </Container>
      {/* END: List of practices */}

      {/* BEGIN: List of people */}
      <Container sx={{ my: 3 }}>
        <Paper component={Container} sx={{ py: 3, mb: 2 }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h4">List of people</Typography>
            <Link href="#" underline="none" color="textSecondary">View all</Link>
          </Stack>
        </Paper>

        <Grid container spacing={2}>
        {Array(4).fill(null).map((_, i) => (
          <Grid key={`${i}`} item xs={12} md={6} lg={3}>
            <PersonCard />
          </Grid>
        ))}
        </Grid>
      </Container>
      {/* END: List of people */}
    </div>
  );
};

export default Dashboard;
