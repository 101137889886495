
import React from 'react';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import AppBar from '../../@lib/components/@common/AppBar';
import PageTitle from '../../@lib/components/@common/PageTitle';
import SearchBar from '../../@lib/components/@common/SearchBar';
import PersonEditor from '../../@lib/components/PersonEditor';

const PersonNew:React.FC = () => {
  return (
    <div>
      <AppBar>
        <Stack direction="row" flexGrow={1} gap={2} justifyContent="space-between" alignItems="center">
          <PageTitle
            title="Add new people"
            subtitle="Lorem ipsum dolor sit amet."
          />

          <SearchBar
            placeholder="Search tree and keyword"
          />
        </Stack>
      </AppBar>
      <Container sx={{ my: 3 }}>
        <PersonEditor />
      </Container>
    </div>
  );
};

export default PersonNew;
