
import React from 'react';
import type { Practice } from '../../@lib/types';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import CTAContainer from '../../@lib/components/@common/CTAContainer';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabLabel from '../../@lib/components/@common/TabLabel';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '../../@lib/components/@common/TableHead';
import TableBody from '../../@lib/components/@common/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Slide from '@mui/material/Slide';
import Fade from '@mui/material/Fade';
import SvgIcon from '@mui/material/SvgIcon';
import AppBar from '../../@lib/components/@common/AppBar';
import SearchBar from '../../@lib/components/@common/SearchBar';
import PageTitle from '../../@lib/components/@common/PageTitle';
import PolicyFilters from '../../@lib/components/PolicyFilters';
import PracticeCard, { practiceCardSkeleton } from '../../@lib/components/PracticeCard';

import { ReactComponent as FileIcon } from '../../@lib/icons/file-icon.svg';
import { ReactComponent as ArrowRightIcon } from '../../@lib/icons/chevron-right-icon.svg';
import { ReactComponent as UploadCloudIcon } from '../../@lib/icons/upload-cloud-icon.svg';

import { useState, useMemo, useCallback } from 'react';
import { useAuthSession } from '../../@lib/components/AuthSessionProvider';
import { useQuery, useMutation, gql } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useNotify } from '../../@lib/components/@common/NotificationsProvider';

export type PracticesCollectionData = {
  practices: Practice[];
};

const fetchPracticesQueryDocument = gql`
query FetchPracticesQuery {
  practices {
    id
    title
    description
    picture
    status
  }
}
`;

const deletePracticeMutationDocument = gql`
mutation DeletePractice ($practiceInput: PracticeDeleteInput!) {
  practiceDelete (input: $practiceInput) {
    practice {
      id
    }
  }
}
`;

const PracticesHome:React.FC = () => {
  const notify = useNotify();
  const navigate = useNavigate();
  const {user} = useAuthSession();
  const isLoggedIn = !!user;
  const {data, loading} = useQuery<PracticesCollectionData>(fetchPracticesQueryDocument, { fetchPolicy: 'cache-and-network' });
  const [deletePractice, { loading: deletingPractice }] = useMutation(deletePracticeMutationDocument);
  const [currTabIndex, setCurrTabIndex] = useState(0);

  const dist = useMemo(() => {
    const dist = {
      approved: [] as Practice[],
      rejected: [] as Practice[],
      pending: [] as Practice[]
    };

    data?.practices.forEach((p) => {
      switch (p.status) {
        case 'rejected':
          dist.rejected.push(p);
          break;
        case 'approved':
          dist.approved.push(p);
          break;
        case 'pending':
        default:
          dist.pending.push(p);
          break;
      }
    });

    return dist;
  }, [data]);

  const practices = (() => {
    switch (currTabIndex) {
      case 1:
        return dist.approved;
      case 2:
        return dist.pending;
      case 3:
        return dist.rejected;
      case 0:
      default:
        return data?.practices || [];
    }
  })();

  /**
   * handleDeletePractice()
   */
  const handleDeletePractice = useCallback(async (practice:Practice) => {
    if (await notify.confirm(`Deleting practice "${practice.title}". This cannot be undone.`)) {
      try {
        await deletePractice({
          variables: {
            practiceInput: {
              id: practice.id
            }
          }
        });

        notify.success(`Practice "${practice.title}" deleted successfuly!`);
      }
      catch (err) {
        notify.error(err as Error);
      }
    }
  }, [deletePractice, notify]);

  /**
   * handleEditPractice()
   */
  const handleEditPractice = useCallback((practice:Practice) => navigate(`/practices/${practice.id}/edit`), [navigate]);

  return (
    <div>
      <AppBar>
        <Stack direction="row" gap={2} alignItems="center" justifyContent="space-between" flexGrow={1}>
          <PageTitle
            title="Practices"
            subtitle="Lorem ipsum dolor sit amet."
          />
          <SearchBar placeholder="Search tree and keyword" />
        </Stack>
        {loading && <LinearProgress sx={{ position: 'absolute', left: 0, right: 0, bottom: 0 }} />}
      </AppBar>

      <Container sx={{ my: 5 }}>
        <Stack direction="row" gap={2} alignItems="center">
          <Typography variant="body2" color="textSecondary">Filter by:</Typography>
          <PolicyFilters />
        </Stack>
      </Container>

      <Container sx={{ my: 3 }}>
        <Paper component={Container} sx={{ py: 1, mb: 2 }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h4">List of practices data</Typography>

            <Tabs value={currTabIndex} onChange={(_, val) => setCurrTabIndex(val)}>
              <Tab label={<TabLabel count={data?.practices.length || 0}>All</TabLabel>} />
              <Tab label={<TabLabel count={dist.approved.length}>Approved</TabLabel>} />
              <Tab label={<TabLabel count={dist.pending.length}>Pending</TabLabel>} />
              <Tab label={<TabLabel count={dist.rejected.length}>Rejected</TabLabel>} />
            </Tabs>

            <Link
              component={RouterLink}
              to="all"
              underline="hover"
              color="textSecondary"
            >View all</Link>
          </Stack>
        </Paper>

        <Grid container spacing={2}>
        {practices.map((p, i) => (
          <Grid key={`${i}`} item xs={12} md={6} lg={3}>
            <Slide in timeout={180 * (i+1)} direction="right">
              <PracticeCard
                practice={p}
                disabled={loading || deletingPractice}
                onDelete={handleDeletePractice}
                onEdit={handleEditPractice}
                sx={{ height: '100%' }}
              />
            </Slide>
          </Grid>
        ))}

        {loading && practices.length === 0 && Array(4).fill(null).map((_, i) => (
          <Fade key={`${i}`} in timeout={180 * (i+1)}>
            <Grid item xs={12} md={6} lg={3}>
              {practiceCardSkeleton}
            </Grid>
          </Fade>
        ))}
        </Grid>
      </Container>

      {isLoggedIn && (
        <Container sx={{ my: 3 }}>
          <Fade in timeout={800}>
            <CTAContainer sx={{ textAlign: 'center' }}>
              <Typography variant="subtitle1" mb={2}>Add new practices to the platform</Typography>
              <Button
                component={RouterLink}
                to="new"
                sx={{
                  px: 6,
                  backgroundColor: '#ffffff',
                  color: 'primary.dark',
                  '&:hover': {
                    backgroundColor: 'primary.light'
                  }
                }}
              >CREATE</Button>
            </CTAContainer>
          </Fade>
        </Container>
      )}

      <Container sx={{ my: 3 }}>
        <Paper component={Container} sx={{ py: 3, mb: 2 }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h4">Latest Reports</Typography>
            <Link href="#" underline="none" color="textSecondary">View all</Link>
          </Stack>
        </Paper>

        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Date Created</TableCell>
                  <TableCell align="right">View</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {[].map((_, i) => (
                  <TableRow key={`${i}`}>
                    <TableCell>
                      <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                        <Avatar sx={{ mr: 2 }}>
                          <SvgIcon component={FileIcon} inheritViewBox />
                        </Avatar>
                        <Typography variant="h6" whiteSpace="nowrap">List of reports based on names</Typography>
                      </Box>
                    </TableCell>
                    <TableCell>18.05.2024</TableCell>
                    <TableCell align="right">
                      <SvgIcon component={ArrowRightIcon} inheritViewBox />
                    </TableCell>
                  </TableRow>
                ))}

                {[].length === 0 && (
                  <TableRow>
                    <TableCell colSpan={2}>
                      No data
                    </TableCell>
                    <TableCell />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Container>

      {isLoggedIn && (
        <Container sx={{ my: 3 }}>
          <Paper component={Container} sx={{ py: 3, mb: 2 }}>
            <Typography variant="h4">Data Import</Typography>
          </Paper>

          <Paper component={Stack} sx={{ py: 6, mb: 2 }} alignItems="center">
            <Avatar sx={{ mb: 2 }}>
              <SvgIcon component={UploadCloudIcon} inheritViewBox />
            </Avatar>
            <Typography mb={0.5}>Click to upload or drag and drop</Typography>
            <Typography variant="caption" color="textSecondary">PDF file (max. 50mb)</Typography>
          </Paper>

          <Box textAlign="right">
            <Button variant="contained" sx={{ px: 6 }} disabled>Import</Button>
          </Box>
        </Container>
      )}
    </div>
  );
};

export default PracticesHome;
