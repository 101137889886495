
import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import AppBar from '../../@lib/components/@common/AppBar';
import PageTitle from '../../@lib/components/@common/PageTitle';
import SearchBar from '../../@lib/components/@common/SearchBar';
import PolicyEditor, { PolicyInputData } from '../../@lib/components/PolicyEditor';

import { useCallback } from 'react';
import { useMutation, gql } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useNotify } from '../../@lib/components/@common/NotificationsProvider';

const createPolicyMutationDocument = gql`
mutation createPolicyPutation ($policyInputData: PolicyCreateInput!) {
  policyCreate (input: $policyInputData) {
    policy {
      id
    }
  }
}
`;

const PolicyNew:React.FC = () => {
  const notify = useNotify();
  const navigate = useNavigate();
  const [createPolicy, { loading }] = useMutation(createPolicyMutationDocument);

  /**
   * handleFinish()
   */
  const handleFinish = useCallback(async (data:PolicyInputData) => {
    try {
      await createPolicy({
        variables: {
          policyInputData: {
            policyInput: data
          }
        }
      });

      navigate('/policies', { replace: true });
    }
    catch (err) {
      notify.error((err as Error));
    }
    
  }, [navigate, createPolicy, notify]);

  /**
   * handleCancel()
   */
  const handleCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <Box mb={24}>
      <AppBar>
        <Stack direction="row" flexGrow={1} gap={2} justifyContent="space-between" alignItems="center">
          <PageTitle
            title="Create new policies"
            subtitle="Lorem ipsum dolor sit amet."
          />

          <SearchBar
            placeholder="Search tree and keyword"
          />
        </Stack>
      </AppBar>

      <Container sx={{ my: 3 }}>
        <Paper component={Container} sx={{ py: 3 }}>
          <PolicyEditor
            disabled={loading}
            onFinish={handleFinish}
            onCancel={handleCancel}
          />
        </Paper>
      </Container>
    </Box>
  );
};

export default PolicyNew;
