
import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import AppBar from '../../@lib/components/@common/AppBar';
import PageTitle from '../../@lib/components/@common/PageTitle';
import SearchBar from '../../@lib/components/@common/SearchBar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import StatusTag from '../../@lib/components/@common/StatusTag';
import LinearProgress from '@mui/material/LinearProgress';
import Skeleton from '@mui/material/Skeleton';
import CoverImage from '../../@lib/components/@common/CoverImage';

import { Policy } from '../../@lib/types';
import { useParams } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import imagePlaceholder from '../../@lib/images/image-placeholder.svg';

const policyQueryDocument = gql`
query policyQuery ($policyId: ID!) {
  policy (id: $policyId) {
    title
    description
    country {
      name
    }
    policyCategory {
      name
    }
    theme {
      title
    }
    geographicalArea {
      name
    }
    picture
    document
    link
    date
    status
  }
}
`;

const PolicyDetails:React.FC = () => {
  const params = useParams();
  const {data, loading} = useQuery<{policy: Policy}>(policyQueryDocument, { variables: { policyId: params.policyId } });

  return (
    <div>
      <AppBar>
        <Stack direction="row" flexGrow={1} justifyContent="space-between" alignItems="center" gap={2}>
          <PageTitle
            title="Policies"
            subtitle="Lorem, ipsum dolor sit amet consectetur"
          />

          <SearchBar
            placeholder="Search tree and keyword"
          />
        </Stack>
        {loading && <LinearProgress sx={{ position: 'absolute', left: 0, right: 0, bottom: 0 }} />}
      </AppBar>

      <Container sx={{ my: 3 }}>
        <Stack direction={{ lg: 'row' }} spacing={3}>
          <Paper component={Container} sx={{ py: 3, flex: 3 }}>
            <CoverImage
              image={data?.policy.picture || imagePlaceholder}
              height={400}
              mb={2}
            />

            {loading ? (
              <Skeleton variant="rounded" width={100} />
            ):(
              <StatusTag label={data?.policy.status} status={data?.policy.status} />
            )}

            <Box mt={3}>
              {loading ? (
                <>
                  <Skeleton variant="text" height={32} width={200} sx={{ mb: 0.5 }} />
                  <Skeleton variant="text" height={16} width="80%" />
                  <Skeleton variant="text" height={16} width="40%" />
                </>
              ):(
                <>
                  <Typography variant="h4" gutterBottom>{data?.policy.title}</Typography>
                  <Typography variant="body2">{data?.policy.description}</Typography>
                </>
              )}
              
            </Box>

            <Grid container spacing={1} my={2}>
              <Grid item xs={12} md={6} lg={3}>
                <Typography component="p" variant="caption" gutterBottom>Practices</Typography>
                None
                {/* <Chip label="Practice Name" sx={{ my: 1 }}/>
                <Chip label="Practice Name 2" sx={{ my: 1 }}/> */}
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <Typography component="p" variant="caption" gutterBottom>People</Typography>
                None
                {/* <Chip label="John Walker" sx={{ my: 1 }}/>
                <Chip label="John Dow" sx={{ my: 1 }}/> */}
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <Typography component="p" variant="caption" gutterBottom>Country</Typography>
                <Typography mb={2}>{data?.policy.country.name}</Typography>

                <Typography component="p" variant="caption" gutterBottom>Date voted</Typography>
                <Typography mb={2}>{data?.policy.date || '--'}</Typography>

                <Typography component="p" variant="caption" gutterBottom>Geographical Zone</Typography>
                <Typography mb={2}>{data?.policy.geographicalArea.name}</Typography>
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <Typography component="p" variant="caption" gutterBottom>Theme</Typography>
                <Typography mb={2}>{data?.policy.theme.title}</Typography>

                <Typography component="p" variant="caption" gutterBottom>Category</Typography>
                <Typography mb={2}>{data?.policy.policyCategory.name}</Typography>

                <Typography component="p" variant="caption" gutterBottom>External Policy Link</Typography>
                <Typography mb={2}>
                  {data?.policy.link ? (
                    <Link href={data.policy.link} target="_BLANK" noWrap display="block">{data.policy.link}</Link>
                  ):(
                    '--'
                  )}</Typography>
              </Grid>
            </Grid>
          </Paper>

          <Paper component={Container} sx={{ py: 3, flex: 1 }}>
            <Typography variant="h4" gutterBottom>Related policies</Typography>
          </Paper>
        </Stack>
      </Container>
    </div>
  );
};

export default PolicyDetails;
