
import React from 'react';
import Typography from '@mui/material/Typography';

export type AppLogoProps = {
  size?: 'small'|'medium'|'large';
};

const AppLogo:React.FC<AppLogoProps> = (props) => {
  const {
    size='large'
  } = props;

  // TODO: This is a placeholder. Implement actual logo when it is available.
  return (
    <Typography variant={size === 'large' ? 'h2' : 'h3'} color="inherit">Brand Logo</Typography>
  );
};

export default AppLogo;
