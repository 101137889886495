
import MuiTableBody from '@mui/material/TableBody';
import { styled } from '@mui/material/styles';

const TableBody = styled(MuiTableBody)(({ theme }) => ({
  '& td:first-of-type': {
    paddingLeft: theme.spacing(7),
    textAlign: 'left'
  },
  '& td:last-child': {
    paddingRight: theme.spacing(7),
    textAlign: 'right'
  }
}));

export default TableBody;
