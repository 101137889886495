
import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import CTAContainer from '../../@lib/components/@common/CTAContainer';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabLabel from '../../@lib/components/@common/TabLabel';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '../../@lib/components/@common/TableHead';
import TableBody from '../../@lib/components/@common/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import LinearProgress from '@mui/material/LinearProgress';
import Slide from '@mui/material/Slide';
import Fade from '@mui/material/Fade';
import SvgIcon from '@mui/material/SvgIcon';
import AppBar from '../../@lib/components/@common/AppBar';
import SearchBar from '../../@lib/components/@common/SearchBar';
import PageTitle from '../../@lib/components/@common/PageTitle';
import PolicyFilters from '../../@lib/components/PolicyFilters';
import PolicyCard, { policyCardSkeleton } from '../../@lib/components/PolicyCard';

import { ReactComponent as FileIcon } from '../../@lib/icons/file-icon.svg';
import { ReactComponent as ArrowRightIcon } from '../../@lib/icons/chevron-right-icon.svg';
import { ReactComponent as UploadCloudIcon } from '../../@lib/icons/upload-cloud-icon.svg';

import { Policy, IDType } from '../../@lib/types';
import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, gql } from '@apollo/client';
import usePoliciesCollection, { PolicyStatus } from '../../@lib/hooks/usePoliciesCollection';
import { useNotify } from '../../@lib/components/@common/NotificationsProvider';
import { useAuthSession } from '../../@lib/components/AuthSessionProvider';

const policyDeleteMutationDocument = gql`
mutation policyDeleteMutation($policy: PolicyDeleteInput!) {
  policyDelete (input: $policy) {
    policy {
      id
    }
  }
}
`;

const PoliciesHome:React.FC = () => {
  const notify = useNotify();
  const navigate = useNavigate();
  const {user} = useAuthSession();
  const isLoggedIn = !!user;
  const [deletePolicy, { loading:deletingPolicy }] = useMutation(policyDeleteMutationDocument);
  const [currTab, setCurrTab] = useState<PolicyStatus|'all'>('all');
  const {policies, dist, loading, refetch} = usePoliciesCollection(currTab);

  /**
   * handleDeletePolicy()
   */
  const handleDeletePolicy = useCallback(async (policy:Pick<Policy, 'id'|'title'>) => {
    if (await notify.confirm(`Deleting policy "${policy.title}". This cannot be undone.`)) {
      try {
        await deletePolicy({ variables: { policy: { id: policy.id } } })
        refetch()
      }
      catch (err) {
        notify.error((err as Error));
      }
    }
  }, [deletePolicy, refetch, notify]);

  /**
   * handleEditPolicy()
   */
  const handleEditPolicy = useCallback((policyId:IDType) => navigate(`/policies/${policyId}/edit`), [navigate]);

  return (
    <div>
      <AppBar>
        <Stack direction="row" gap={2} alignItems="center" justifyContent="space-between" flexGrow={1}>
          <PageTitle
            title="Policies"
            subtitle="Lorem ipsum dolor sit amet."
          />
          <SearchBar placeholder="Search tree and keyword" />
        </Stack>
        {loading && <LinearProgress sx={{ position: 'absolute', left: 0, right: 0, bottom: 0 }} />}
      </AppBar>

      <Container sx={{ my: 5 }}>
        <Stack direction="row" gap={2} alignItems="center">
          <Typography variant="body2" color="textSecondary">Filter by:</Typography>
          <PolicyFilters />
        </Stack>
      </Container>

      <Container sx={{ my: 3 }}>
        <Paper component={Container} sx={{ py: 1, mb: 2 }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h4">List of policies data</Typography>

            <Tabs value={currTab} onChange={(_, val) => setCurrTab(val)}>
              <Tab value="all" label={<TabLabel count={dist.all.length}>All</TabLabel>} />
              <Tab value="approved" label={<TabLabel count={dist.approved.length}>Approved</TabLabel>} />
              <Tab value="pending" label={<TabLabel count={dist.pending.length}>Pending</TabLabel>} />
              <Tab value="rejected" label={<TabLabel count={dist.rejected.length}>Rejected</TabLabel>} />
            </Tabs>

            <Link component={RouterLink} to="all" underline="none" color="textSecondary">View all</Link>
          </Stack>
        </Paper>
        <Grid container spacing={2}>
          {policies.map((p, i) => (
            <Grid key={`${i}`} item xs={12} md={6} lg={3}>
              <Slide in timeout={180 * (i+1)} direction="right">
                <PolicyCard
                  {...p}
                  disabled={deletingPolicy}
                  onDelete={() => handleDeletePolicy(p)}
                  onEdit={handleEditPolicy}
                  sx={{ height: '100%' }}
                />
              </Slide>
            </Grid>
          ))}

          {loading && policies.length === 0 && Array(4).fill(null).map((_, i) => (
            <Fade key={`${i}`} in timeout={180 * (i+1)}>
              <Grid item xs={12} md={6} lg={3}>
                {policyCardSkeleton}
              </Grid>
            </Fade>
          ))}
        </Grid>
      </Container>

      {isLoggedIn && (
        <Container sx={{ my: 3 }}>
          <Fade in timeout={800}>
            <CTAContainer sx={{ textAlign: 'center' }}>
              <Typography variant="subtitle1" mb={2}>Add new policies to the platform</Typography>
              <Button
                component={RouterLink}
                to="new"
                sx={{
                  px: 6,
                  backgroundColor: '#ffffff',
                  color: 'primary.dark',
                  '&:hover': {
                    backgroundColor: 'primary.light'
                  }
                }}
              >CREATE</Button>
            </CTAContainer>
          </Fade>
        </Container>
      )}

      <Container sx={{ my: 3 }}>
        <Paper component={Container} sx={{ py: 3, mb: 2 }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h4">Latest Reports</Typography>
            <Link href="#" underline="none" color="textSecondary">View all</Link>
          </Stack>
        </Paper>

        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Date Created</TableCell>
                  <TableCell align="right">View</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {[].map((_, i) => (
                  <TableRow key={`${i}`}>
                    <TableCell>
                      <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                        <Avatar sx={{ mr: 2 }}>
                          <SvgIcon component={FileIcon} inheritViewBox />
                        </Avatar>
                        <Typography variant="h6" whiteSpace="nowrap">List of reports based on names</Typography>
                      </Box>
                    </TableCell>
                    <TableCell>18.05.2024</TableCell>
                    <TableCell align="right">
                      <SvgIcon component={ArrowRightIcon} inheritViewBox />
                    </TableCell>
                  </TableRow>
                ))}

                {!loading && [].length === 0 && (
                  <TableRow>
                    <TableCell colSpan={2}>No Data</TableCell>
                    <TableCell />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Container>

      {isLoggedIn && (
        <Container sx={{ my: 3 }}>
          <Paper component={Container} sx={{ py: 3, mb: 2 }}>
            <Typography variant="h4">Data Import</Typography>
          </Paper>

          <Paper component={Stack} sx={{ py: 6, mb: 2 }} alignItems="center">
            <Avatar sx={{ mb: 2 }}>
              <SvgIcon component={UploadCloudIcon} inheritViewBox />
            </Avatar>
            <Typography mb={0.5}>Click to upload or drag and drop</Typography>
            <Typography variant="caption" color="textSecondary">PDF file (max. 50mb)</Typography>
          </Paper>

          <Box textAlign="right">
            <Button variant="contained" sx={{ px: 6 }} disabled>Import</Button>
          </Box>
        </Container>
      )}
    </div>
  );
};

export default PoliciesHome;
