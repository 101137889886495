
import React from 'react';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import SvgIcon from '@mui/material/SvgIcon';
import { Controller } from 'react-hook-form';

import { ReactComponent as UploadCloudIcon } from '../../icons/upload-cloud-icon.svg';
import { useFormContext } from 'react-hook-form';

export type PolicyInfoFormProps = {
  countryOptions: {label: string; value: string|number;}[];
  themeOptions: {label: string; value: string|number;}[];
  categoryOptions: {label: string; value: string|number;}[];
  geographicalAreaOptions: {label: string; value: string|number;}[];
  disabled?: boolean;
};

const PolicyInfoForm:React.FC<PolicyInfoFormProps> = (props) => {
  const { disabled } = props;
  const { trigger } = useFormContext();

  return (
    <Stack direction={{ xs: 'column-reverse', md: 'row' }} gap={3}>
      <Stack flex={1} gap={2}>
        <FormControl fullWidth>
          <FormLabel>Upload Image</FormLabel>
          <Paper component={Stack} sx={{ py: 10 }} alignItems="center" variant="outlined">
            <Avatar sx={{ mb: 2 }}>
              <SvgIcon component={UploadCloudIcon} inheritViewBox />
            </Avatar>
            <Typography mb={0.5}>Click to upload or drag and drop</Typography>
            <Typography variant="caption" color="textSecondary">SVG, PNG, JPG or GIF (max. 800x400px)</Typography>
          </Paper>
        </FormControl>

        <Controller
          name="countryId"
          rules={{ required: 'Required!' }}
          render={({ field, fieldState: {error, invalid} }) => (
            <FormControl fullWidth required error={invalid} disabled={disabled}>
              <FormLabel>Country</FormLabel>
              <Select
                {...field}
                onChange={(e)=>{
                  field.onChange(e);
                  trigger(field.name);
                }}
                displayEmpty
              >
                <MenuItem value="" disabled sx={{ color: 'text.secondary' }}><em>Select country</em></MenuItem>
                {props.countryOptions.map((op, i) => (
                  <MenuItem key={`${op.value}-${i}`} value={op.value}>{op.label}</MenuItem>
                ))}
              </Select>
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          )}
        />
        
        <Controller
          name="themeId"
          rules={{ required: 'Required!' }}
          render={({ field, fieldState: {error} }) => (
            <FormControl fullWidth required error={!!error} disabled={disabled}>
              <FormLabel>Theme</FormLabel>
              <Select
                {...field}
                onChange={(e)=>{
                  field.onChange(e);
                  trigger(field.name);
                }}
                displayEmpty
              >
                <MenuItem value="" disabled sx={{ color: 'text.secondary' }}><em>Select theme</em></MenuItem>
                {props.themeOptions.map((op, i) => (
                  <MenuItem key={`${op.value}-${i}`} value={op.value}>{op.label}</MenuItem>
                ))}
              </Select>
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          )}
        />
        
        <Controller
          name="policyCategoryId"
          rules={{ required: 'Required!' }}
          render={({ field, fieldState: {error} }) => (
            <FormControl fullWidth required error={!!error} disabled={disabled}>
              <FormLabel>Category</FormLabel>
              <Select
                {...field}
                onChange={(e)=>{
                  field.onChange(e);
                  trigger(field.name);
                }}
                displayEmpty
              >
                <MenuItem value="" disabled sx={{ color: 'text.secondary' }}><em>Select category</em></MenuItem>
                {props.categoryOptions.map((op, i) => (
                  <MenuItem key={`${op.value}-${i}`} value={op.value}>{op.label}</MenuItem>
                ))}
              </Select>
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          )}
        />

        <Controller
          name="geographicalAreaId"
          rules={{ required: 'Required!' }}
          render={({ field, fieldState: {error} }) => (
            <FormControl fullWidth required error={!!error} disabled={disabled}>
              <FormLabel>Geographical Zone</FormLabel>
              <Select
                {...field}
                onChange={(e)=>{
                  field.onChange(e);
                  trigger(field.name);
                }}
                displayEmpty
              >
                <MenuItem value="" disabled sx={{ color: 'text.secondary' }}>Select geographical zone</MenuItem>
                {props.geographicalAreaOptions.map((op, i) => (
                  <MenuItem key={`${op.value}-${i}`} value={op.value}>{op.label}</MenuItem>
                ))}
              </Select>
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          )}
        />
      </Stack>

      <Stack flex={1} gap={2}>
        <Controller
          name="title"
          rules={{ required: 'Required!' }}
          render={({ field, fieldState: {error} }) => (
            <FormControl required error={!!error} disabled={disabled}>
              <FormLabel>Policy Name</FormLabel>
              <OutlinedInput
                {...field}
                onChange={(e)=>{
                  field.onChange(e);
                  trigger(field.name);
                }}
              />
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          )}
        />

        <Controller
          name="description"
          rules={{ required: 'Required!' }}
          render={({ field, fieldState: {error} }) => (
            <FormControl required error={!!error} disabled={disabled}>
              <FormLabel>Policy Description</FormLabel>
              <OutlinedInput
                multiline
                rows={3}
                {...field}
                onChange={(e)=>{
                  field.onChange(e);
                  trigger(field.name);
                }}
              />
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          )}
        />
        
        <Controller
          name="date"
          render={({ field }) => (
            <FormControl disabled={disabled}>
              <FormLabel>Date voted</FormLabel>
              <OutlinedInput {...field}/>
            </FormControl>
          )}
        />

        <FormControl fullWidth>
          <FormLabel>Upload document</FormLabel>
          <Paper component={Stack} sx={{ py: 4 }} alignItems="center" variant="outlined">
            <Avatar sx={{ mb: 2 }}>
              <SvgIcon component={UploadCloudIcon} inheritViewBox />
            </Avatar>
            <Typography mb={0.5}>Click to upload or drag and drop</Typography>
            <Typography variant="caption" color="textSecondary">Xls, Pdf or Graph are allowed to upload</Typography>
          </Paper>
        </FormControl>

        <Controller
          name="link"
          render={({ field }) => (
            <FormControl disabled={disabled}>
              <FormLabel>External policy link</FormLabel>
              <OutlinedInput {...field}/>
            </FormControl>
          )}
        />
      </Stack>
    </Stack>
  );
};

export default PolicyInfoForm;
