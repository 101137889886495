
import React from 'react';
import type { Practice } from '../../@lib/types';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabLabel from '../../@lib/components/@common/TabLabel';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import AppBar from '../../@lib/components/@common/AppBar';
import PageTitle from '../../@lib/components/@common/PageTitle';
import CTAContainer from '../../@lib/components/@common/CTAContainer';
import SearchBar from '../../@lib/components/@common/SearchBar';
import StatusTag from '../../@lib/components/@common/StatusTag';

import { useState, useCallback, useMemo } from 'react';
import { useAuthSession } from '../../@lib/components/AuthSessionProvider';
import { useQuery, useMutation, gql } from '@apollo/client';
import { useNotify } from '../../@lib/components/@common/NotificationsProvider';
import { useNavigate } from 'react-router-dom';
import placeholderImage from '../../@lib/images/image-placeholder.svg';

export type PracticesCollectionData = {
  practices: Practice[];
};

const fetchPracticesQueryDocument = gql`
query FetchPracticesQuery {
  practices {
    id
    title
    description
    picture
    status
  }
}
`;

const deletePracticeMutationDocument = gql`
mutation DeletePractice ($practiceInput: PracticeDeleteInput!) {
  practiceDelete (input: $practiceInput) {
    practice {
      id
    }
  }
}
`;

const PracticesList:React.FC = () => {
  const notify = useNotify();
  const navigate = useNavigate();
  const {user} = useAuthSession();
  const isLoggedIn = !!user;
  const {data, loading} = useQuery<PracticesCollectionData>(fetchPracticesQueryDocument, { fetchPolicy: 'cache-and-network' });
  const [deletePractice, { loading: deletingPractice }] = useMutation(deletePracticeMutationDocument);
  const [currTabIndex, setCurrTabIndex] = useState(0);

  const dist = useMemo(() => {
    const dist = {
      approved: [] as Practice[],
      rejected: [] as Practice[],
      pending: [] as Practice[]
    };

    data?.practices.forEach((p) => {
      switch (p.status) {
        case 'rejected':
          dist.rejected.push(p);
          break;
        case 'approved':
          dist.approved.push(p);
          break;
        case 'pending':
        default:
          dist.pending.push(p);
          break;
      }
    });

    return dist;
  }, [data]);

  const practices = (() => {
    switch (currTabIndex) {
      case 1:
        return dist.approved;
      case 2:
        return dist.pending;
      case 3:
        return dist.rejected;
      case 0:
      default:
        return data?.practices || [];
    }
  })();

  /**
   * handleDeletePractice()
   */
  const handleDeletePractice = useCallback(async (practice:Practice) => {
    if (await notify.confirm(`Deleting practice "${practice.title}". This cannot be undone.`)) {
      try {
        await deletePractice({
          variables: {
            practiceInput: {
              id: practice.id
            }
          }
        });

        notify.success(`Practice "${practice.title}" deleted successfuly!`);
      }
      catch (err) {
        notify.error(err as Error);
      }
    }
  }, [deletePractice, notify]);

  /**
   * handleEditPractice()
   */
  const handleEditPractice = useCallback((practice:Practice) => navigate(`/practices/${practice.id}/edit`), [navigate]);

  return (
    <div>
      <AppBar>
        <Stack direction="row" flexGrow={1} alignItems="center" justifyContent="space-between">
          <PageTitle
            title="Practices"
            subtitle="Lorem ipsum dolor sit, amet consectetur adipisicing elit."
          />

          <SearchBar
            placeholder="Search tree and keyword"
          />
        </Stack>
      </AppBar>

      <Container sx={{ my: 3 }}>
        <Paper component={Container} sx={{ py: 1, my: 2, display: 'flex', justifyContent: 'center' }}>
          <Tabs value={currTabIndex} onChange={(_, val) => setCurrTabIndex(val)}>
            <Tab label={<TabLabel count={data?.practices.length}>All</TabLabel>} />
            <Tab label={<TabLabel count={dist.approved.length}>Approved</TabLabel>} />
            <Tab label={<TabLabel count={dist.pending.length}>Pending</TabLabel>} />
            <Tab label={<TabLabel count={dist.rejected.length}>Rejected</TabLabel>} />
          </Tabs>
        </Paper>

        <Stack gap={2}>
        {practices.map((p, i) => (
          <Card key={`${i}`} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <CardMedia
              component="img"
              sx={{ flexBasis: 150, maxWidth: 150, p: 2, borderRadius: 4 }}
              height={150}
              image={p.picture || placeholderImage}
              alt="Practice papers"
            />
            <CardContent component={Stack} sx={{ flexGrow: 1 }} justifyContent="space-between" alignItems="flex-start" gap={2}>
              <div>
                <Typography variant="h6">{p.title}</Typography>
                <Typography component="p" variant="caption">{p.description}</Typography>
              </div>

              <StatusTag label={p.status} status={p.status}/>
            </CardContent>
            {isLoggedIn && (
              <Stack flexBasis="15%" gap={2}>
                <Button disabled={deletingPractice || loading} size="small" variant="contained" onClick={() => handleEditPractice(p)}>EDIT</Button>
                <Button disabled={deletingPractice || loading} size="small" variant="contained" color="error" onClick={() => handleDeletePractice(p)}>DELETE</Button>
              </Stack>
            )}
          </Card>
        ))}
        </Stack>
      </Container>

      <Container sx={{ my: 3 }}>
        <Paper sx={{ py: 2, display: 'flex', justifyContent: 'center' }}>
          <Link color="textSecondary" underline="none">Show more</Link>
        </Paper>
      </Container>

      {isLoggedIn && (
        <Container sx={{ my: 3 }}>
          <CTAContainer sx={{ textAlign: 'center' }}>
            <Typography variant="subtitle1" mb={2}>Add new practices to the platform</Typography>
            <Button
              component={RouterLink}
              to="/practices/new"
              sx={{
                px: 6,
                backgroundColor: '#ffffff',
                color: 'primary.dark',
                '&:hover': {
                  backgroundColor: 'primary.light'
                }
              }}
            >CREATE</Button>
          </CTAContainer>
        </Container>
      )}
    </div>
  );
};

export default PracticesList;
