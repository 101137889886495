
import MuiTableHead from '@mui/material/TableHead';
import { styled } from '@mui/material/styles';

const TableHead = styled(MuiTableHead)(({ theme }) => ({
  '& th': {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    position: 'relative',
    color: theme.palette.text.secondary,
    fontSize: 12,
    fontWeight: 600,
    '&:before': {
      content: `""`,
      display: 'inline-block',
      position: 'absolute',
      pointerEvents: 'none',
      top: theme.spacing(3.5),
      bottom: theme.spacing(3.5),
      left: 0,
      right: 0,
      border: '1px solid #E4E7EC',
      borderLeftWidth: 0,
      borderRightWidth: 0
    }
  },
  '& th:first-of-type': {
    paddingLeft: theme.spacing(7),
    textAlign: 'left',
    '&:before': {
      borderLeftWidth: 1,
      left: theme.spacing(2.5),
      borderRadius: '100px 0 0 100px'
    }
  },
  '& th:last-child': {
    paddingRight: theme.spacing(7),
    textAlign: 'right',
    '&:before': {
      borderRightWidth: 1,
      right: theme.spacing(2.5),
      borderRadius: '0 100px 100px 0'
    }
  }
}));

export default TableHead;
