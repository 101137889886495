
import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

const Support:React.FC = () => {
  return (
    <Container>
      <Typography variant="overline">This page is under development</Typography>
    </Container>
  );
};

export default Support;
