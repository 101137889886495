
import type React from 'react';
import type { Practice } from '../../types';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Card, { CardProps } from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import StatusTag from '../@common/StatusTag';

import { forwardRef } from 'react';
import { useAuthSession } from '../AuthSessionProvider';
import { styled } from '@mui/material/styles';
import imagePlaceholder from '../../images/image-placeholder.svg';

export type PracticeCardProps = Omit<CardProps, 'id'> & {
  practice: Practice;
  disabled?: boolean;
  onDelete?: (practice: Practice) => void;
  onEdit?: (practice: Practice) => void;
};

export const practiceCardSkeleton = (
  <div>
    <Skeleton variant="rounded" height={240} sx={{ mb: 1 }} />
    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 1 }}>
      <Skeleton variant="rounded" width="30%" />
      <Box flexGrow={1} display="flex" flexDirection="column" alignItems="flex-end">
        <Skeleton variant="text" width="50%" height={20} />
        <Skeleton variant="text" width="30%" height={16} />
      </Box>
    </Stack>
    <Skeleton variant="text" width="40%" height={20} />
    <Skeleton variant="text" width="80%" height={16} />

    <Stack direction="row" justifyContent="space-between" sx={{ mt: 4 }}>
      <Skeleton variant="rounded" width="45%" height={24} />
      <Skeleton variant="rounded" width="45%" height={24} />
    </Stack>
  </div>
);

const CardActionButton = styled(Button)(({ theme }) => ({
  textTransform: 'uppercase',
  borderRadius: 100,
  paddingTop: theme.spacing(0.25),
  paddingBottom: theme.spacing(0.25),
  fontSize: 12,
  flex: 1,
  color: '#ffffff'
}));

const PracticeCard:React.ForwardRefRenderFunction<HTMLDivElement, PracticeCardProps> = (props, ref) => {
  const {
    practice,
    disabled,
    onEdit,
    onDelete,
    children,
    ...cardProps
  } = props;

  const {user} = useAuthSession();
  const isLoggedIn = !!user;

  return (
    <Card {...cardProps} ref={ref}>
      <CardActionArea component={RouterLink} to={`${practice.id}`} disabled={disabled}>
        <CardMedia
          component="img"
          height={280}
          image={practice.picture || imagePlaceholder}
          alt="practice papers"
        />
      </CardActionArea>
      <CardContent>
        <Stack direction="row" justifyContent="space-between">
          <StatusTag label={practice.status} status={practice.status} />
          
          <div style={{ textAlign: 'right' }}>
            <Typography lineHeight={1}>Olivia Rhye</Typography>
            <Typography variant="caption">17.05.2024</Typography>
          </div>
        </Stack>
        <Typography variant="h6">{practice.title}</Typography>
        <Typography component="p" variant="caption" noWrap>{practice.description}</Typography>
      </CardContent>
      {isLoggedIn && (
        <Stack direction="row" spacing={1} sx={{ p: 2, width: '100%' }}>
          <CardActionButton
            size="small"
            variant="contained"
            color="warning"
            disabled={disabled}
            onClick={() => onEdit && onEdit(practice)}
          >EDIT</CardActionButton>
          <CardActionButton
            size="small"
            variant="contained"
            color="error"
            disabled={disabled}
            onClick={() => onDelete && onDelete(practice)}
          >DELETE</CardActionButton>
        </Stack>
      )}
    </Card>
  );
};

export default forwardRef(PracticeCard);
