
import React from 'react';
import MuiChip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';

export type TabLabelProps = {
  count?: number;
  hideCount?: boolean;
  children?: React.ReactNode;
};

const LabelWrapper = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  '.Mui-selected > & > span': {
    color: theme.palette.text.primary,
    textDecoration: 'underline',
    textDecorationThickness: 2,
    textDecorationColor: theme.palette.primary.main
  }
}));

const Chip = styled(MuiChip)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 500,
  color: 'inherit',
  height: theme.spacing(2.25),
  textDecoration: 'none',
  '.Mui-selected &': {
    fontWeight: 700,
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    textDecoration: 'overline'
  }
}))

const TabLabel:React.FC<TabLabelProps> = (props) => {
  const {
    count,
    hideCount,
    children
  } = props;

  return (
    <LabelWrapper>
      <span>{children}</span>
      {typeof count === 'number' && !hideCount && (
        <Chip label={count} />
      )}
    </LabelWrapper>
  );
};

export default TabLabel;
