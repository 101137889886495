
import { useMemo } from 'react';
import { useQuery, gql } from '@apollo/client';

export type PolicyStatus = 'pending'|'approved'|'rejected';

export type PolicyData = {
  id: string|number;
  title: string;
  description: string;
  picture?: string;
  status: PolicyStatus;
};

export type PoliciesCollectionData = {
  policies: PolicyData[];
};

const policiesQueryDocument = gql`
query policiesQuery {
  policies {
    id
    title
    description
    picture
    status
  }
}
`;

const usePoliciesCollection = (policyStatus?:PolicyStatus|'all') => {
  const {data, loading, refetch} = useQuery<PoliciesCollectionData>(policiesQueryDocument, { fetchPolicy: 'cache-and-network' });

  const dist = useMemo(() => {
    const dist = {
      approved: [] as PolicyData[],
      rejected: [] as PolicyData[],
      pending: [] as PolicyData[]
    };

    data?.policies.forEach((p) => {
      switch (p.status) {
        case 'rejected':
          dist.rejected.push(p);
          break;
        case 'approved':
          dist.approved.push(p);
          break;
        case 'pending':
        default:
          dist.pending.push(p);
          break;
      }
    });

    return dist;
  }, [data]);

  const policies = (() => {
    switch (policyStatus) {
      case 'approved':
        return dist.approved;
      case 'pending':
        return dist.pending;
      case 'rejected':
        return dist.rejected;
      default:
        return data?.policies || [];
    }
  })();

  return {
    policies,
    dist: {
      all: data?.policies || [],
      ...dist
    },
    loading,
    refetch
  };
}

export default usePoliciesCollection;
