
import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Outlet } from 'react-router-dom';
import AppLogo from '../AppLogo';

import { styled } from '@mui/material/styles';
import bgGradient from '../../images/bg-gradient.jpg';

const BrandingBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundImage: `url(${bgGradient})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  color: '#ffffff'
}));

const AuthLayout:React.FC = () => {
  return (
    <Stack direction={{ xs: 'column-reverse', lg: 'row' }} minHeight="100vh">
      <Box flex={1}>
        <Outlet />
      </Box>
      
      <BrandingBox flex={{ xs: 'initial', lg: 1 }}>
        <Container sx={{ py: 5, width: 'auto', textAlign: 'center' }}>
          <AppLogo />
          <Typography mt={1}>Brand Slogan will come here</Typography>
        </Container>
      </BrandingBox>
    </Stack>
  );
};

export default AuthLayout;
