
import React from 'react';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MuiSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { styled } from '@mui/material/styles';

const Select = styled(MuiSelect)(({ theme }) => ({
  borderRadius: 100,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[4],
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  }
}));

const PolicyFilters:React.FC = () => {
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <FormControl sx={{ minWidth: 120 }}>
        <InputLabel>Country</InputLabel>
        <Select
          label="Country"
        >
          <MenuItem value="france">France</MenuItem>
        </Select>
      </FormControl>
      
      <FormControl sx={{ minWidth: 120 }}>
        <InputLabel>Period</InputLabel>
        <Select
          label="Period"
        ></Select>
      </FormControl>

      <FormControl sx={{ minWidth: 120 }}>
        <InputLabel>Theme</InputLabel>
        <Select
          label="Theme"
        ></Select>
      </FormControl>
    </Stack>
  );
};

export default PolicyFilters;
