
import React from 'react';
import AuthLayout from './@lib/components/AuthLayout';
import DrawerLayout from './@lib/components/@common/DrawerLayout';
import Login from './auth/Login';
import Dashboard from './Dashboard';
import PoliciesHome from './policies/PoliciesHome';
import PoliciesList from './policies/PoliciesList';
import PolicyNew from './policies/PolicyNew';
import PolicyEdit from './policies/PolicyEdit';
import PolicyDetails from './policies/PolicyDetails';
import PracticesHome from './practices/PracticesHome';
import PracticesList from './practices/PracticesList';
import PracticeNew from './practices/PracticeNew';
import PracticeEdit from './practices/PracticeEdit';
import PracticeDetails from './practices/PracticeDetails';
import PeopleHome from './people/PeopleHome';
import PeopleList from './people/PeopleList';
import PersonNew from './people/PersonNew';
import Support from './Support';
import AccountSettings from './AccountSettings';
import { Navigate } from 'react-router-dom';
import MainSideNav from './@lib/components/MainSideNav';

import { createBrowserRouter } from 'react-router-dom';
import { useAuthSession } from './@lib/components/AuthSessionProvider';

type AuthGuardProps = {
  policy: 'require_auth' | 'require_no_auth';
  redirectTo: string;
  children?: React.ReactNode;
};

const AuthGuard:React.FC<AuthGuardProps> = (props) => {
  const { user } = useAuthSession();
  const isAuthenticated = !!user;
  switch (props.policy) {
    case 'require_auth': {
      if (isAuthenticated)
        return <>{props.children}</>;
      else
        return <Navigate to={props.redirectTo} />
    }
    case 'require_no_auth': {
      if (!isAuthenticated)
        return <>{props.children}</>;
      else
        return <Navigate to={props.redirectTo} />
    }
    default: {
      return <>{props.children}</>;
    }
  }
};

const router = createBrowserRouter([
  {
    path: '',
    element: <DrawerLayout drawerContent={<MainSideNav />} />,
    children: [
      {index: true, element: <Dashboard />},
      {path: 'policies', element: <PoliciesHome />},
      {path: 'policies/all', element: <PoliciesList />},
      {path: 'policies/:policyId', element: <PolicyDetails />},

      {path: 'practices', element: <PracticesHome />},
      {path: 'practices/all', element: <PracticesList />},
      {path: 'practices/:practiceId', element: <PracticeDetails />},

      {path: 'people', element: <PeopleHome />},
      {path: 'people/all', element: <PeopleList />},
      
      {path: 'support', element: <Support />}
    ]
  },
  {
    path: '',
    element: (
      <AuthGuard policy="require_auth" redirectTo="/auth/login">
        <DrawerLayout drawerContent={<MainSideNav />} />
      </AuthGuard>
    ),
    children: [
      {path: 'policies/:policyId/edit', element: <PolicyEdit />},
      {path: 'policies/new', element: <PolicyNew />},

      {path: 'practices/new', element: <PracticeNew />},
      {path: 'practices/:practiceId/edit', element: <PracticeEdit />},

      {path: 'people/new', element: <PersonNew />},

      {path: 'account-settings', element: <AccountSettings />},
    ]
  },
  {
    path: 'auth',
    element: (
      <AuthGuard policy="require_no_auth" redirectTo="/">
        <AuthLayout />
      </AuthGuard>
    ),
    children: [
      {index: true, element: <Navigate to="login" />},
      {path: 'login', element: <Login />}
    ]
  }
]);

export default router;
