
import React from 'react';
import type { Policy, IDType } from '../../@lib/types';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabLabel from '../../@lib/components/@common/TabLabel';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import StatusTag from '../../@lib/components/@common/StatusTag';
import AppBar from '../../@lib/components/@common/AppBar';
import PageTitle from '../../@lib/components/@common/PageTitle';
import CTAContainer from '../../@lib/components/@common/CTAContainer';
import SearchBar from '../../@lib/components/@common/SearchBar';
import imagePlaceholder from '../../@lib/images/image-placeholder.svg';

import { useState, useCallback } from 'react';
import { useAuthSession } from '../../@lib/components/AuthSessionProvider';
import usePoliciesCollection, { PolicyStatus } from '../../@lib/hooks/usePoliciesCollection';
import { useMutation, gql } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useNotify } from '../../@lib/components/@common/NotificationsProvider';

const policyDeleteMutationDocument = gql`
mutation policyDeleteMutation($policy: PolicyDeleteInput!) {
  policyDelete (input: $policy) {
    policy {
      id
    }
  }
}
`;

const PoliciesList:React.FC = () => {
  const notify = useNotify();
  const navigate = useNavigate();
  const {user} = useAuthSession();
  const isLoggedIn = !!user;
  const [deletePolicy, { loading:deletingPolicy }] = useMutation(policyDeleteMutationDocument);
  const [currTab, setCurrTab] = useState<PolicyStatus|'all'>('all');
  const {policies, dist, loading, refetch} = usePoliciesCollection(currTab);

  /**
   * handleDeletePolicy()
   */
  const handleDeletePolicy = useCallback(async (policy:Pick<Policy, 'id'|'title'>) => {
    if (await notify.confirm(`Deleting policy "${policy.title}". This cannot be undone.`)) {
      try {
        await deletePolicy({ variables: { policy: { id: policy.id } } })
        refetch()
      }
      catch (err) {
        notify.error((err as Error));
      }
    }
  }, [deletePolicy, refetch, notify]);

  /**
   * handleEditPolicy()
   */
  const handleEditPolicy = useCallback((policyId:IDType) => navigate(`/policies/${policyId}/edit`), [navigate]);

  return (
    <div>
      <AppBar>
        <Stack direction="row" flexGrow={1} alignItems="center" justifyContent="space-between">
          <PageTitle
            title="Policies"
            subtitle="Lorem ipsum dolor sit, amet consectetur adipisicing elit."
          />

          <SearchBar
            placeholder="Search tree and keyword"
          />
        </Stack>
      </AppBar>

      <Container sx={{ my: 3 }}>
        <Paper component={Container} sx={{ py: 1, my: 2, display: 'flex', justifyContent: 'center' }}>
          <Tabs value={currTab} onChange={(_, val) => setCurrTab(val)}>
            <Tab value="all" label={<TabLabel count={dist.all.length}>All</TabLabel>} />
            <Tab value="approved" label={<TabLabel count={dist.approved.length}>Approved</TabLabel>} />
            <Tab value="pending" label={<TabLabel count={dist.pending.length}>Pending</TabLabel>} />
            <Tab value="rejected" label={<TabLabel count={dist.rejected.length}>Rejected</TabLabel>} />
          </Tabs>
        </Paper>

        <Stack gap={2}>
          {policies.map((p, i) => (
            <Card key={`${i}`} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <CardActionArea
                component={RouterLink}
                to={`/policies/${p.id}`}
                disabled={deletingPolicy || loading}
                sx={{ flexBasis: 150, maxWidth: 150 }}
              >
                <CardMedia
                  component="img"
                  sx={{ p: 2, borderRadius: 4 }}
                  height={150}
                  image={imagePlaceholder}
                  alt="policy papers"
                />
              </CardActionArea>
              <CardContent component={Stack} sx={{ flexGrow: 1 }} justifyContent="space-between" alignItems="flex-start" gap={2}>
                <div>
                  <Typography variant="h6">{p.title}</Typography>
                  <Typography component="p" variant="caption">{p.description}</Typography>
                </div>

                <StatusTag label={p.status} status={p.status} />
              </CardContent>
              {isLoggedIn && (
                <Stack flexBasis="15%" gap={2}>
                  <Button disabled={deletingPolicy || loading} size="small" variant="contained" onClick={() => handleEditPolicy(p.id)}>EDIT</Button>
                  <Button disabled={deletingPolicy || loading} size="small" variant="contained" color="error" onClick={() => handleDeletePolicy(p)}>DELETE</Button>
                </Stack>
              )}
            </Card>
          ))}
        </Stack>
      </Container>

      <Container sx={{ my: 3 }}>
        <Paper sx={{ py: 2, display: 'flex', justifyContent: 'center' }}>
          <Link color="textSecondary" underline="none">Show more</Link>
        </Paper>
      </Container>

      {isLoggedIn && (
        <Container sx={{ my: 3 }}>
          <CTAContainer sx={{ textAlign: 'center' }}>
            <Typography variant="subtitle1" mb={2}>Add new policies to the platform</Typography>
            <Button
              component={RouterLink}
              to="/policies/new"
              sx={{
                px: 6,
                backgroundColor: '#ffffff',
                color: 'primary.dark',
                '&:hover': {
                  backgroundColor: 'primary.light'
                }
              }}
            >CREATE</Button>
          </CTAContainer>
        </Container>
      )}
    </div>
  );
};

export default PoliciesList;
