
import MuiChip from '@mui/material/Chip';
import { styled, lighten } from '@mui/material/styles';

const DataChip = styled(MuiChip)(({ theme, color }) => ({
  padding: theme.spacing(1.75),
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: (color && color !== 'default') ? theme.palette[color].main : 'inherit',
  backgroundColor: (color && color !== 'default') ? lighten(theme.palette[color].main, 0.9) : 'inherit',
  color: (color && color !== 'default') ? theme.palette[color].main : 'inherit'
})) as typeof MuiChip;

export default DataChip;
