
import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

export type PracticesInfoFormProps = {
  disabled?: boolean;
};

const PracticesInfoForm:React.FC<PracticesInfoFormProps> = () => {
  return (
    <Stack gap={2}>
      <Box component={Paper} variant="outlined" bgcolor="grey.100" sx={{ minHeight: 300, p: 2 }}>
        <Typography variant="caption">No practices already selected</Typography>
      </Box>

      <Stack direction={{ md: 'row' }} gap={2} alignItems={{ md: 'flex-end' }}>
        <FormControl sx={{ flexBasis: { xs: '100%', md: '60%', lg: '40%' } }} disabled>
          <FormLabel>Select practices</FormLabel>
          <Select
            displayEmpty
          >
            <MenuItem value="">Select practices</MenuItem>
          </Select>
        </FormControl>

        <Button variant="contained" disabled>Create new practice</Button>
      </Stack>
    </Stack>
  );
};

export default PracticesInfoForm;
