
import localforage from "localforage";

const store = localforage.createInstance({
  name: 'auth'
});

const USER_KEY = 'curr_user';
const ACCESS_TOKEN_KEY = 'access_token';
const REFRESH_TOKEN_KEY = 'refresh_token';
const TOKEN_EXPIRY_KEY = 'token_expiry';

export const setUser = <T extends object>(user:T) => store.setItem(USER_KEY, user);
export const getUser = <T extends object>() => store.getItem<T>(USER_KEY);
export const setAccessToken = (token:string) => store.setItem(ACCESS_TOKEN_KEY, token);
export const getAccessToken = () => store.getItem<string>(ACCESS_TOKEN_KEY);
export const setRefreshToken = (token:string) => store.setItem(REFRESH_TOKEN_KEY, token);
export const getRefreshToken = () => store.getItem<string>(REFRESH_TOKEN_KEY);
export const setTokenExpiry = (timestamp:number) => store.setItem(TOKEN_EXPIRY_KEY, timestamp);
export const getTokenExpiry = () => store.getItem<number>(TOKEN_EXPIRY_KEY);
export const clear = () => store.clear();
