
import React from 'react';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import CssBaseline from '@mui/material/CssBaseline';
import { RouterProvider } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import AuthSessionProvider from './@lib/components/AuthSessionProvider';
import NotificationsProvider from './@lib/components/@common/NotificationsProvider';

import lightTheme from './@lib/themes/theme-light';
import router from './router';
import graphqlClient from './@lib/services/api/graphql-client';

function App() {
  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      <ApolloProvider client={graphqlClient}>
        <AuthSessionProvider>
          <NotificationsProvider>
            <RouterProvider router={router} />
          </NotificationsProvider>
        </AuthSessionProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
}

export default App;
