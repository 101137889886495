
import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export type CoverImageProps = BoxProps & {
  image: string;
};

const CoverImage = styled(Box, { shouldForwardProp: (prop) => prop !== 'image' })<CoverImageProps>(({ theme, image }) => ({
  borderRadius: theme.shape.borderRadius,
  backgroundImage: `url(${image})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover'
}));

export default CoverImage;
