
import React from 'react';
import MuiOutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as SearchIcon } from '../../../icons/search-icon.svg';
import { styled } from '@mui/material/styles';

export type SearchBarProps = Omit<OutlinedInputProps, 'startAdornment'>;

const OutlinedInput = styled(MuiOutlinedInput)(({ theme }) => ({
  fontSize: 14,
  borderRadius: 100,
  backgroundColor: theme.palette.background.default,
  transition: 'background-color 300ms',
  '&:hover': {
    backgroundColor: theme.palette.background.paper
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.primary.main,
    transition: 'border-color 300ms',
  }
}));

const SearchBar:React.FC<SearchBarProps> = (props) => {
  return (
    <OutlinedInput
      {...props}
      startAdornment={
        <InputAdornment position="start">
          <SvgIcon component={SearchIcon} inheritViewBox color="primary" />
        </InputAdornment>
      }
    />
  );
};

export default SearchBar;
