
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';

export type StatusTagProps = {
  status?: 'pending'|'approved'|'rejected';
};

const StatusTag = styled(Chip, { shouldForwardProp: (prop) => prop !== 'status' })<StatusTagProps>(({ theme, status }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  textTransform: 'capitalize',
  
  ...(status === 'pending' && {
    color: theme.palette.warning.main,
    backgroundColor: theme.palette.warning.light
  }),

  ...(status === 'approved' && {
    color: theme.palette.success.main,
    backgroundColor: theme.palette.success.light
  }),

  ...(status === 'rejected' && {
    color: theme.palette.error.main,
    backgroundColor: theme.palette.error.light
  })
}));

export default StatusTag;
