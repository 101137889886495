
import React from 'react';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';

const PersonEditor:React.FC = () => {
  return (
    <div>
      <Paper component={Container} sx={{ py: 3, mb: 3 }}>
        <Stack direction={{ md: 'row' }} spacing={3}>
          <div style={{ flex: 1 }}>
            <FormLabel>Upload Image</FormLabel>
          </div>
          <Stack spacing={2} flex={1}>
            <FormControl>
              <FormLabel>First name</FormLabel>
              <OutlinedInput
                placeholder="Enter first name"
              />
            </FormControl>
            <FormControl>
              <FormLabel>Last name</FormLabel>
              <OutlinedInput
                placeholder="Enter last name"
              />
            </FormControl>
            <FormControl>
              <FormLabel>Description</FormLabel>
              <OutlinedInput
                multiline
                rows={6}
              />
            </FormControl>
          </Stack>
        </Stack>
      </Paper>

      <Paper component={Container} sx={{ py: 2, mb: 2 }}>
        <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
          <Typography variant="h4">Career</Typography>
          <Button variant="contained">Add more</Button>
        </Stack>
      </Paper>

      <Paper component={Container} sx={{ py: 3 }}>
        <Stack direction={{ md: 'row' }} sx={{ mb: 3 }} spacing={3}>
          <Stack direction={{ sm: 'row' }} spacing={2} flex={1}>
            <FormControl sx={{ flex: 1 }}>
              <FormLabel>Institution</FormLabel>
              <Select></Select>
            </FormControl>

            <FormControl sx={{ flex: 1 }}>
              <FormLabel>Role</FormLabel>
              <Select></Select>
            </FormControl>
          </Stack>
          <Stack direction={{ sm: 'row' }} spacing={2} flex={1} alignItems="flex-end">
            <FormControl sx={{ flex: 1 }}>
              <FormLabel>Period</FormLabel>
              <OutlinedInput
                placeholder="Start date"
              />
            </FormControl>
            <FormControl sx={{ flex: 1 }}>
              <OutlinedInput
                placeholder="End date"
              />
            </FormControl>
          </Stack>
        </Stack>

        <Tabs sx={{ width: 'fit-content', my: 2 }} value={0}>
          <Tab label="Action" />
          <Tab label="Position" />
        </Tabs>

        <Stack direction={{ md: 'row' }} gap={2} alignItems="flex-end">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={3}>
              <FormControl fullWidth>
                <FormLabel>Action</FormLabel>
                <Select></Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <FormControl fullWidth>
                <FormLabel>Sector</FormLabel>
                <Select></Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <FormControl fullWidth>
                <FormLabel>Country</FormLabel>
                <Select></Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <FormControl fullWidth>
                <FormLabel>Date</FormLabel>
                <OutlinedInput />
              </FormControl>
            </Grid>
          </Grid>

          <div style={{ flexShrink: 0 }}>
            <Button variant="outlined" color="success">Add more</Button>
          </div>
        </Stack>
        
      </Paper>
    </div>
  );
};

export default PersonEditor;
