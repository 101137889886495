
import React from 'react';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabLabel from '../../@lib/components/@common/TabLabel';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import AppBar from '../../@lib/components/@common/AppBar';
import PageTitle from '../../@lib/components/@common/PageTitle';
import CTAContainer from '../../@lib/components/@common/CTAContainer';
import SearchBar from '../../@lib/components/@common/SearchBar';

import { useAuthSession } from '../../@lib/components/AuthSessionProvider';

const PeopleList:React.FC = () => {
  const {user} = useAuthSession();
  const isLoggedIn = !!user;

  return (
    <div>
      <AppBar>
        <Stack direction="row" flexGrow={1} alignItems="center" justifyContent="space-between">
          <PageTitle
            title="People"
            subtitle="Lorem ipsum dolor sit, amet consectetur adipisicing elit."
          />

          <SearchBar
            placeholder="Search tree and keyword"
          />
        </Stack>
      </AppBar>

      <Container sx={{ my: 3 }}>
        <Paper component={Container} sx={{ py: 1, my: 2, display: 'flex', justifyContent: 'center' }}>
          <Tabs value={0}>
            <Tab label={<TabLabel count={90}>All</TabLabel>} />
            <Tab label={<TabLabel count={50}>Approved</TabLabel>} />
            <Tab label={<TabLabel count={25}>Pending</TabLabel>} />
            <Tab label={<TabLabel count={15}>Rejected</TabLabel>} />
          </Tabs>
        </Paper>

        <Stack gap={2}>
        {Array(8).fill(null).map((_, i) => (
          <Card key={`${i}`} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <CardMedia
              component="img"
              sx={{ flexBasis: 150, maxWidth: 150, p: 2, borderRadius: 4 }}
              height={150}
              image="https://d1s9j44aio5gjs.cloudfront.net/2023/02/Swim-England-Board-Policy-Banner.jpg"
              alt="policy papers"
            />
            <CardContent component={Stack} sx={{ flexGrow: 1 }} justifyContent="space-between" alignItems="flex-start" gap={2}>
              <div>
                <Typography variant="h6">Person Name</Typography>
                <Typography variant="body2">Country</Typography>
                <Typography component="p" variant="caption">
                  Details
                </Typography>
              </div>

              <Chip label="Approved" size="small" />
            </CardContent>
            {isLoggedIn && (
              <Stack flexBasis="20%" gap={2}>
                <Button size="small" variant="contained">EDIT</Button>
                <Button size="small" variant="contained">DELETE</Button>
              </Stack>
            )}
          </Card>
        ))}
        </Stack>
      </Container>

      <Container sx={{ my: 3 }}>
        <Paper sx={{ py: 2, display: 'flex', justifyContent: 'center' }}>
          <Link color="textSecondary" underline="none">Show more</Link>
        </Paper>
      </Container>

      {isLoggedIn && (
        <Container sx={{ my: 3 }}>
          <CTAContainer sx={{ textAlign: 'center' }}>
            <Typography variant="subtitle1" mb={2}>Add new people to the platform</Typography>
            <Button
              component={RouterLink}
              to="/people/new"
              sx={{
                px: 6,
                backgroundColor: '#ffffff',
                color: 'primary.dark',
                '&:hover': {
                  backgroundColor: 'primary.light'
                }
              }}
            >Add People</Button>
          </CTAContainer>
        </Container>
      )}
    </div>
  );
};

export default PeopleList;
