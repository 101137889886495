
import { createContext } from 'react';

export type NotificationsContext = {
  success: (message:string)=>void;
  error: (error:Error)=>void;
  confirm: (message:string, options?:{ confirmButtonText?: string; cancelButtonText?: string })=>Promise<boolean>;
};

const notificationsContext = createContext<NotificationsContext>({
  success: alert,
  error: alert,
  confirm: (message) => new Promise((res) => window.confirm(message) ? res(true) : res(false))
});

export default notificationsContext;
