
import React from 'react';
import MuiStepper, { StepperProps as MuiStepperProps } from '@mui/material/Stepper';
import MuiStep from '@mui/material/Step';
import MuiStepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import MuiStepLabel, { stepLabelClasses } from '@mui/material/StepLabel';
import { styled } from '@mui/material/styles';

export type Step = {
  label?: string;
  icon?: React.ReactNode;
};

export type StepperProps = MuiStepperProps & {
  steps: Step[];
};

const StepConnector = styled(MuiStepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 25
  },
  [`&.${stepConnectorClasses.active}, &.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main
    }
  }
}));

const StepLabel = styled(MuiStepLabel)(({ theme }) => ({
  [`& .${stepLabelClasses.iconContainer}`]: {
    width: 42,
    height: 42,
    borderRadius: 100,
    backgroundColor: theme.palette.text.secondary,
    color: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
    marginTop: 4,
    marginBottom: 4,

    [`&.${stepLabelClasses.active}, &.${stepLabelClasses.completed}`]: {
      backgroundColor: theme.palette.primary.main,
      width: 50,
      height: 50,
      marginTop: 0,
      marginBottom: 0
    },

    [`&.${stepLabelClasses.active}`]: {
      
    }
  },
  [`& .${stepLabelClasses.label}`]: {
    marginTop: theme.spacing(1.75),

    [`&.${stepLabelClasses.active}, &.${stepLabelClasses.completed}`]: {
      fontWeight: 600
    }
  }
}));

const Stepper:React.FC<StepperProps> = (props) => {
  const {
    steps,
    ...muiStepperProps
  } = props;

  return (
    <MuiStepper
      {...muiStepperProps}
      connector={<StepConnector />}
      alternativeLabel
    >
    {steps.map((step, i) => (
      <MuiStep key={`${i}`}>
        <StepLabel icon={step.icon}>{step.label}</StepLabel>
      </MuiStep>
    ))}
    </MuiStepper>
  );
};

export default Stepper;
