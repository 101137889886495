
import React from 'react';
import { PracticeInputData } from '../../@lib/types';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import AppBar from '../../@lib/components/@common/AppBar';
import PageTitle from '../../@lib/components/@common/PageTitle';
import SearchBar from '../../@lib/components/@common/SearchBar';
import PracticeEditor from '../../@lib/components/PracticeEditor';

import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, gql } from '@apollo/client';
import { useNotify } from '../../@lib/components/@common/NotificationsProvider';

const createPracticeMutationDocument = gql`
mutation CreatePracticeMutation ($practiceInput: PracticeCreateInput!) {
  practiceCreate (input: $practiceInput) {
    practice {
      id
    }
  }
}
`;

const PracticeNew:React.FC = () => {
  const notify = useNotify();
  const navigate = useNavigate();
  const [createPractice, { loading }] = useMutation(createPracticeMutationDocument);

  /**
   * handleFinish()
   */
  const handleFinish = useCallback(async (data:PracticeInputData) => {
    try {
      await createPractice({
        variables: {
          practiceInput: {
            practiceInput: data
          }
        }
      });

      navigate('/practices', { replace: true });
    }
    catch (err) {
      notify.error((err as Error));
    }
  }, [createPractice, navigate, notify]);

  /**
   * handleCancel
   */
  const handleCancel = useCallback(() => navigate(-1), [navigate]);

  return (
    <Box mb={24}>
      <AppBar>
        <Stack direction="row" flexGrow={1} gap={2} justifyContent="space-between" alignItems="center">
          <PageTitle
            title="Create new practices"
            subtitle="Lorem ipsum dolor sit amet."
          />

          <SearchBar
            placeholder="Search tree and keyword"
          />
        </Stack>
      </AppBar>

      <Container sx={{ my: 3 }}>
        <Paper component={Container} sx={{ py: 3 }}>
          <PracticeEditor
            onCancel={handleCancel}
            onFinish={handleFinish}
            disabled={loading}
          />
        </Paper>
      </Container>
    </Box>
  );
};

export default PracticeNew;
